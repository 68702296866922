import React from 'react';

import './App.css';
import { ZoomMtg } from '@zoomus/websdk';
import queryString from 'query-string'

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.3.5/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function App() {


  var url_string = (window.location.href);
  var url = new URL(url_string);
  var data = url.search.split("?");

  if(data != ""){
  
    var signatureEndpoint = 'https://api.easyhaionline.com/api/zoom/signature'
    var sdkKey = '1X5bKPWVYyWTvL6FkMbJgBnbxhwQhTLwKPqY'
    var meetingNumber = data[1].split("=")[1];
    var role = data[3].split("=")[1];
    var leaveUrl = data[5].split("=")[1];
    var userName = data[4].split("=")[1];
    var userEmail = ''
    var passWord = data[2].split("=")[1];
    var registrantToken = ''
  
    getSignature();

  }

  function getSignature(e) {

    fetch(signatureEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
    .then(response => {
      startMeeting(response.signature)
    }).catch(error => {
      console.error(error)
    })
  }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          sdkKey: sdkKey,
          userEmail: userEmail,
          passWord: passWord,
          tk: registrantToken,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <div className="App">
      <main>
        <h1>Zoom Meeting </h1>

        {/* <button onClick={getSignature}>Join Meeting</button> */}
      </main>
    </div>
  );
}

export default App;
